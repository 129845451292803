import React from "react";
import "./Dashboard.scss"; // Assuming you're using SCSS for styling
import { AppWrap } from "../../wrapper";
const Dashboard = () => {
  // Embed URL from Power BI
  const embedUrl =
    "https://app.powerbi.com/view?r=eyJrIjoiODA4ZDQ2MTMtMzdlYi00ZTc3LTg4NDAtZjZkMTJjMThlNTBmIiwidCI6ImM2NmUxODMzLWY2M2UtNGI5Zi05NDc5LWZhMDdiY2NhMTAzMyIsImMiOjEwfQ%3D%3D&pageName=ReportSection";

  return (
    <div className="app__dashboard app__flex">
      <iframe
        title="Report Section"
        width="1024"
        height="612"
        src="https://app.powerbi.com/view?r=eyJrIjoiODA4ZDQ2MTMtMzdlYi00ZTc3LTg4NDAtZjZkMTJjMThlNTBmIiwidCI6ImM2NmUxODMzLWY2M2UtNGI5Zi05NDc5LWZhMDdiY2NhMTAzMyIsImMiOjEwfQ%3D%3D&pageName=ReportSection"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
};

export default Dashboard;
