import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { About, Footer, Header, Skills, Testimonial, Work, Contacts, Dashboard } from "./container";
import { Navbar, NavbarOut } from "./components";

const App = () => {
  return (
    <Router basename="/">
      <div className="app">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Header />
                <About />
                <Skills />
                <Work />
                {/* Uncomment these as needed */}
                {/* <Testimonial />
              <Contacts />
              <Footer /> */}
              </>
            }
          />
          <Route
            path="/dashboard"
            element={
              <>
                <NavbarOut />
                <Dashboard />
              </>
            }
          />{" "}
          {/* Add this line for Dashboard */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
